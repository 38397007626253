import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <div className="row">
      <div className="col">
        <h1 className="text-center">Well, that can't be found!</h1>
      </div>
    </div>

    <div className="row">
      <div className="col">
        <p className="text-center">
          Since there's nothing here, why don't you checkout my recent{" "}
          <Link to="/technologies">technologies</Link>, or my{" "}
          <Link to="/projects">projects</Link>?
        </p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
